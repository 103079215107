从 "react "导入 React、{ useContext }
从 "./context" 中导入 { SlicesMapContext, SlicesResultsContext }

export const InlineSlice = ({
  sliceName、
  allowEmpty、
  children、
  ...切片道具
}) => {
  const slicesMap = useContext(SlicesMapContext)
  const slicesResultsMap = useContext(SlicesResultsContext)
  const concreteSliceName = slicesMap[sliceName]
  const slice = slicesResultsMap.get(concreteSliceName)

  if (!slice) {
    if (allowEmpty) {
      返回空
    } else {
      throw new Error(
        未找到"${sliceName}"槽的"${concreteSliceName}"片
      )
    }
  }

  返回 (
    <slice.component sliceContext="{slice.sliceContext}" data="{slice.data}" {...sliceProps}="">
      {孩子们｝
    </slice.component>
  )
}
